body {
  margin: 0;
  height: 100vh;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;
}
.app .navbar {
  background-color: #fff;
  min-height: 80px !important;
  position: static;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  padding-right: 0 !important;
  padding-left: 0 !important;
  z-index: 10;
  border-bottom: 1px solid #5F6366;
}
.app .shadow {
  margin: 0 auto;
}
.app .shadow.bottom {
  box-shadow: 0px 15px 10px -15px #5F6366;
}
.app .SideBarLogo {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 270px;
  padding: 3px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14;
  letter-spacing: 1px;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
}
.app .SideBarLogo img {
  width: 150px;
  height: 50px;
}
.app .SideBarLogo .caption {
  display: block;
}
.app .sidebar-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .sidebar-btn-wrapper .sidebar-btn {
  transition: width 0.3s;
  width: 150px;
  padding: 1px 15px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.05);
  color: #adadad;
  text-decoration: none;
  margin: 0 auto;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
}
.app .sidebar-btn-wrapper .sidebar-btn span {
  margin-left: 5px;
  font-size: 13px;
}
.app .sidebar-btn-wrapper .sidebar-btn:hover {
  color: #dee2ec;
}
.app .btn-toggle {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: #353535;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  display: none;
  overflow: initial;
  padding: 0.3px;
}
.app .collapsed .sidebar-btn {
  display: inline-block;
  line-height: 40px;
  padding: 0;
}
.app .block {
  display: flex;
  margin-bottom: 24px;
  font-size: 14px;
  color: #545454;
}
.app .block > span {
  margin-left: 10px;
}
.app main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  clear: both;
}
.app main header {
  margin-bottom: 20px;
  border-bottom: 1px solid #000000;
}
.app main header h1 {
  display: fixed;
  align-items: center;
  transform: translateX(-20px);
}
.app main footer {
  margin-top: auto;
  color: #888;
  text-align: center;
}
.app main footer a {
  text-decoration: none;
  color: #888;
}
.app main footer a:hover {
  text-decoration: underline;
}
.app main .text {
  position: relative;
  padding: 24px;
  vertical-align: top;
}
@media (max-width: 768px) {
  .app .btn-toggle {
    display: initial;
  }
}

.navbar-vertical .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.dropdown-toggle.caret-off::after {
  display: none;
}

.jazu-nav-dropdown .dropdown-menu {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.jazu-lang-toggle {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  line-height: 1.5;
  color: #5e6e82;
  font-size: 1rem;
}

#table-customers-body {
  border-top: none;
}
