.map-100 {
  height: 100vh;
} 

.map-50 {
  height: 50vh;
} 

.map-25 {
  height: 25vh;
} 

.map-20 {
  height: 20vh;
} 
