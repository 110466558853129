.loading-indicator {
    text-align: center;
  }
   
  .block-ui {
    position: relative;
    min-height: 3em;
  }
   
  .block-ui-container {
    position: absolute;
    z-index: 1010;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    min-height: 2em;
    cursor: wait;
    overflow: hidden;
  }
   
  .block-ui-container:focus {
    outline: none;
  }
   
  .block-ui-overlay {
    width: 100%;
    height: 100%;
    opacity: 0.75;
    filter: alpha(opacity=50);
    background-color: rgb(184, 184, 184);
  }
   
  .block-ui-message-container {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    z-index: 10001;
  }
   
  .block-ui-message {
    color: #333;
    background: none;
    z-index: 1011;
  }
   
  #indicator {
    width: 100px;
    height: 100px;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    animation: spin 1s linear infinite;
  }
   
  #circle {
    fill: none;
    stroke: rgba(255, 255, 255, 0.5);
    stroke-linecap: round;
    stroke-width: 4;
    animation: draw 3s ease-in-out infinite;
  }
   
  @keyframes draw {
    0% {
      stroke-dasharray: 20, 282.6;
    }
    50% {
      stroke-dasharray: 200, 282.6;
    }
    100% {
      stroke-dasharray: 20, 282.6;
    }
  }
   
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
